const SHCLeads = {

    aiden: {
        name: "Aiden Nightwalker",
        nick: "Aiden",
        pronouns: "-",
        photo: {
            location: "aiden.png",
            align: "top-left"
        },
        since: "A long time",
        about: "Hi there! I've been spawning and running trains for a long time (back when we used to walk up and down the stone path with our minions out trying to figure out how to spawn Nandi). When I'm not doing anything hunt related I am usually trying my best not to be a trap in any content.  If you are interested in anything hunt related or have any questions about Siren Hunts please reach out to myself or any of these wonderful Siren Hunters",
        roles: "Faloop Lead"
    },
    airenlei: {
        name: "Airenlei Saerel",
        nick: "Aiai",
        pronouns: "She/Her",
        photo: {
            location: "airenlei2.png",
            align: "top-left"
        },
        since: "Like a year before EW maybe.",
        about: "idk fam",
        roles: "SHC Admin, SHC Web Admin, Little Lala Creator"
    },
    arolas: {
        name: "Arolas Legwen",
        nick: "Aro",
        pronouns: "She/Her",
        photo: {
            location: "arolas.png",
            align: "bottom-left"
        },
        since: "June 2021 (around Patch 5.57)",
        about: "just me - always rioting, always sounding angy, love foods, love boba, love furbabies, plays siege, volleyball is life, student, and love cooking and baking for friends and family. In-Game - savage raider, indecisive designer for FC, and sometimes hunts achievements (dammit Kharnak). Hunts - I like spinning, doing dumb things, and /stalking fresh meat/ helping/inducting new hunters",
        roles: "Faloop Lead"
    },
    beau: {
        name: "Beau Bellator",
        nick: "Beau",
        pronouns: "They/Them",
        photo: {
            location: "beau.png",
            align: "bottom-right"
        },
        since: "Patch 2.3 (Naul Pls)",
        about: "Hi I'm Beau! I've been spawning and hunting since the very start of hunts! While I tend to take more of a quiet route to hunting now, I am always open to answer any questions about hunts. If I'm not going feral on hunts you will usually find me in Eureka/Bozja or crying at some fishing windows.",
        roles: "Faloop Lead"
    },
    freya: {
        name: "Freya Tennebrae",
        nick: "Freya",
        pronouns: "She/Her",
        photo: {
            location: "freya.png",
            align: "bottom-left"
        },
        since: "Late Shadowbringers",
        about: "I am always tired, I like boolet trains, and I take payment for all hunt services in Triple Cream Coffee. :)",
        roles: "Faloop Lead, AH Rep"
    },
    harbinger: {
        name: "Harbinger Prime",
        nick: "Harbinger, one can assume",
        pronouns: "He/Him",
        photo: {
            location: "harbiny.png",
            align: "bottom-right"
        },
        since: "Late ~5.5",
        about: "I started by riding trains before finishing the MSQ then started scouting here and there before finally asking someone what more I could do. Now it's mostly spawning, with scouting and/or conducting. Otherwise I like to fish and collect all the things along with doing all the content. Feel free to reach out with comments, questions etc.",
        roles: "Faloop Lead, Prime Hunts Web Admin"
    },
    kai: {
        name: "Kai Ju",
        nick: "Kai, one can assume",
        pronouns: "They/Them",
        photo: {
            location: "kai.png",
            align: "bottom-left"
        },
        since: "Stormblood",
        about: "I’m Kai, I’m an achievement hunter, hardcore raider, mount collector, champion of the common man, and general completionist. I’m always happy to help new or old players enter the hunt scene as well as offer advice on anything else.",
        roles: "Faloop Lead, AH Rep"
    },
    kharnak: {
        name: "Kharnak Kalyeska",
        nick: "Kharnak",
        pronouns: "He/Him",
        photo: {
            location: "kharnak.png",
            align: "bottom-right"
        },
        since: "3 Years",
        about: "Emoting, mount parading Hrothgar chasing fun (mostly collectibles and achievements) in every corner of Etheirys. Cursed completionist, apparently the resident old-timer, and the bluest White Mage.",
        roles: "SHC Admin, Faloop Lead, AH Mod"
    },
    miu: {
        name: "Miu Lavneta",
        nick: "Miu",
        pronouns: "He/Him",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "Early Endwalker",
        about: " Weather Enthusiast, Story Writer, and The Sexiest Frog on Siren.™? I am a completionist at heart, always trying to 100% every game I play, and it's coming back to bite me since I'm now stuck here forever.",
        roles: "Conductor/Scouter"
    },
    petite: {
        name: "Petite Cat",
        nick: "Petite",
        pronouns: "She/Her",
        photo: {
            location: "petite-cat.png",
            align: "bottom-left"
        },
        since: "Patch 5.4",
        about: "Henlo, it me Petite Cat. I started out scouting for SHB and SB trains back in Patch 5.4, got into relaying, created some linkshells, developed a functioning gambling addiction through spawning S-Ranks, conducted my first train on Aether Hunts where I forgot to ping the train role until the second zone, joined this cult called the Siren Hunts Discord… and now I’m here revising this blurb. If you are interested in scouting, relaying, conducting a hunt train, or spawning S-Ranks on Siren, please don’t hesitate to reach out to me. You can message me on Discord @petite_cat or /tell Petite Cat@Siren in-game, and I’ll initiate your entry into our cult.",
        roles: "SHC Admin, Faloop Lead, CH Mod, AH Mod"
    },
    tofu: {
        name: "I’m Tofu",
        nick: "Tofu",
        pronouns: "-",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "Shadowbringers",
        about: "Jambo, I'm Tofu, a hunt achievement enthusiast. I've been hosting hunt trains for about 3 years now, I've ran in almost every Data Center outside of Jp. I enjoy Spawning and doing various kinds of content but my mainly focus on hunts now. If you join my trains you probably already know they can be quite fun and chaotic at times.",
        roles: "Conductor"
    },
    vash: {
        name: "Vashet Saicere",
        nick: "Vash",
        pronouns: "She/Her",
        photo: {
            location: "vashet2.png",
            align: "top-left"
        },
        since: "Hunting since: 4.1",
        about: "Misplaced European. \"Living fossil\" of hunts, mostly keeping to the backstage these days. Miss pre world visit hunts. Still like it when the A ranks fight back. Spawning/fishing encyclopedia of sorts\n" +
            "The Doors of Stone is never coming out",
        roles: "Faloop Lead"
    },
    yumiko: {
        name: "Yumiko Kaneko",
        nick: "Yumi",
        pronouns: "(ᴉo ᴉo ᴉo) ǝᴉssn∀ ǝᴉssn∀ ǝᴉssn∀",
        photo: {
            location: "yumiko.png",
            align: "top-right"
        },
        since: "Yumi has always been here and there and everywhere all at once.",
        about: "One of the few Australian hunters in NA. Appears as a dead BLU in the overworld. Likes spawning multiple things at once for maximum chaos.",
        roles: "SHC Admin, Faloop Lead"
    },


    revo: {
        name: "Revo Lockheart",
        nick: "Revo",
        pronouns: "S/he-",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "ShB",
        about: "Your queer af degen scout, crossworld spawner, and unsanctioned conductor. Willing to teach and will steal talent. Always on the lookout for new hunters- I'll likely notice you before you notice me. (edited)",
        roles: "Scout"
    },
    eildh: {
        name: "Eildh Giorsail",
        nick: "Ellie ( Ay-lee)",
        pronouns: "she/her",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "Med Endwalker",
        about: "I stumbled my way into hunting because of friends, and initially just joined to Scout, but I conducted my first train literally a day later and loved it. I like cozy romps through Farlemald, lots of banter between marks, and the fun little rituals hunters come up with! I like very quick vibes for my trains, and fun for all. Outside of trains, if I'm not running end game content, I'm catching up on the years of stuff I couldn't do because I wasn't playing yet! ?",
        roles: "Conductor"
    },
    akira: {
        name: "Akira Hoenn",
        nick: "Akira",
        pronouns: "Goof/Ball",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "9 Years",
        about: "When I was just a sprout on Primal about 9 years ago, I had joined an FC that had a hunt spawner/scout in it. He would always make sure that I would make it to every hunt train and S-Rank, and he even showed me how to scout and spawn S-Ranks! I have always found hunting intriguing ever since. Eventually, I transferred here to Siren to be with my friends, and I wanted to help out as much as I could in our hunt community, just like he did. And maybe someday, I'll become a conductor and run my own trains! I'm always happy to lend a hand no matter the content! Dungeons, raids, crafting, FISHING? I do it all!",
        roles: "Conductor, Scout"
    },
    happyface: {
        name: "Happy-face Happy-Dog",
        nick: "Happy",
        pronouns: "She/They",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "Dawn Trail",
        about: "Hey pals! I'm Happy, I'm still getting used to the hunt scene but am an avid train rider and I love to help scout! I'm hoping to conduct some trains in the future so watch out for me!",
        roles: "Conductor, Scout"
    },
    koasae: {
        name: "Koa'sae Amariyo",
        nick: "Koa",
        pronouns: "He/They",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "Early 2022",
        about: "Still pretty new to hunts, but I love conducting, scouting, and interacting with the community! Winton^2 fan (we love you grassman and minerva).",
        roles: "Conductor, Scout"
    },
    hanako: {
        name: "Hanako Shinsetsu",
        nick: "Hanako",
        pronouns: "",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "A Realm Reborn",
        about: "I’ve been hunting since I was a teeny tiny lala back in A Realm Reborn! I started scouting in Endwalker and have had a lot of fun doing hunt trains and occasionally spawning S ranks! I also like collecting anything cute or pretty, most mounts/minions and sometimes cards! I like a challenge and have recently gotten into raiding and EX trials!",
        roles: "Scout"
    },
    grayson: {
        name: "Grayson Everschade",
        nick: "Grayson",
        pronouns: "-",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "A Realm Reborn",
        about: "I started hunting back in ARR when hunts were introduced (I played on a different character until the end of ShB) and my first hunt memories are jumping in Witch Drop trying to spawn Safat! I started scouting around the beginning of EW and love helping out when I can. When not hunting, you will find me doing all kinds of things from levelling up, collecting glams/mounts, redecorating my FC house or playing on one of my many alts!",
        roles: "Scout"
    },


    maren: {
        name: "Maren Murasaki",
        nick: "Maren",
        pronouns: "Any-",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "6.0",
        about: "Hello. I'm Maren, I've been scouting for the Tuesday reset train for a while now and some may know me and some may not. I've enjoyed scouting and have grown fond of the hunt community. I don't know what else to put here so I'll use what I have left to say this one thing. Farlameld.",
        roles: "Scout"
    },

    vanjie: {
        name: "Miss Vanjie",
        nick: "Vanjie",
        pronouns: "She/They",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "Hunting since 6.0",
        about: "Hey! I'm Vanjie, the sleepiest bean in Eorzea. If you don't see me Hunting you can probably find me kicking shins in Limsa, suffering in Eureka, or crafting something for my next Gil-making scheme. If you see me in the wild, feel free to shoot me a tell about anything you may need help with, or at least throw some headpats my way.",
        roles: "Conductor"
    },

    alyse: {
        name: "A'lyse Vero",
        nick: "Alyse",
        pronouns: "She/They",
        photo: {
            location: "",
            align: "top-left"
        },
        since: "I have been hunting since I accidentally spawned Pale Rider and had to be told what the message 'you sense the presence of a powerful mark' means. Since that event, roughly 6 months ago, I have been an active part of Hunts, where I met my current partner, by emote spamming at him until he couldn't ignore me X3",
        about: "I am mainly a factory /strategy gamer who fell in love with crafting  and fashion, as well as the import the story has in this game (even though I avoid probably too often. Don't want it to end). Ingame: in-game, I run multiple Hunts activities, but have been mainly trying to focus on spawning and mapping. However, I will run Stormblood trains every now and again, and would consider running other trains if asked. When I am not doing Hunts stuff, you can usually find me working on crafting stuff.",
        roles: "Conductor, Scout"
    }

}

export default SHCLeads